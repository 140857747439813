<template>
  <v-container fluid id="main">
    <div class="error-404">
      <h1>404. Страница не найдена!</h1>
    </div>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: "Ошибка 404",
    titleTemplate: "%s ← Medveddoc",
    meta: [
      {
        name: "description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
      { charset: "utf-8" },
      { property: "og:title", content: "Ошибка 404" },
      { property: "og:site_name", content: "medveddoc.com" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://medveddoc.com" },
      {
        property: "og:description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
    ],
  },
  name: "The404Page",
};
</script>

<style scoped>
* {
  transition: all 0.6s;
}

#main {
  display: table;
  width: 100%;
  height: 50vh;
  text-align: center;
}

.error-404 {
  display: table-cell;
  vertical-align: middle;
}

.error-404 h1 {
  color: #0044cc;
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0 0 #0044cc;
  }
  to {
    box-shadow: inset -3px 0 0 transparent;
  }
}
</style>
